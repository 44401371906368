import { Trans } from '@lingui/macro'
import React from 'react'
import { Zones } from 'shared/types/fleet'
import { FirebaseKey } from 'shared/types/utils'
import { isObjectEmpty } from 'shared/utils/defined'
import { zoneSorter } from 'shared/utils/zone'

interface Props {
  zones: Zones
  selectedZone: FirebaseKey | undefined
  setSelectedZone: (_: FirebaseKey | undefined) => void
}

export const ZoneSelector: React.FC<Props> = ({
  zones,
  selectedZone,
  setSelectedZone,
}) => {
  if (isObjectEmpty(zones)) return null

  const ALL = ''

  return (
    <div className="flex flex-row flex-wrap items-baseline justify-center gap-4">
      <label htmlFor="zone">
        <Trans>Zone :</Trans>
      </label>
      <select
        id="zone"
        value={selectedZone}
        className="rounded bg-blue-500 px-4 py-2 text-white focus:border-blue-600 focus:ring-blue-600"
        onChange={(e) => {
          setSelectedZone(e.target.value === ALL ? undefined : e.target.value)
        }}
      >
        <option value={ALL}>
          <Trans>Toutes</Trans>
        </option>
        {Object.entries(zones)
          .sort(zoneSorter)
          .map(([key, zone]) => (
            <option key={key} value={key}>
              {zone.name}
            </option>
          ))}
      </select>
    </div>
  )
}
