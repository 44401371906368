import { Trans } from '@lingui/macro'
import React from 'react'
import { Facility } from 'shared/types/reporting'
import { FacilityName, Room } from 'shared/types/utils'
import { Title } from './components/Text'
import { RoomConfigs } from './RoomConfigs'
import { Zones } from './Zones'

interface Props {
  facility: Facility
  facilityName: FacilityName
  rooms: Room[]
}

export const Configuration: React.FC<Props> = ({
  facility,
  facilityName,
  rooms,
}) => {
  return (
    <>
      <div className="mt-8">
        <Title>
          <Trans>Configurations des chambres</Trans>
        </Title>
        <RoomConfigs facilityName={facilityName} />
      </div>
      <div className="mt-8">
        <Title>
          <Trans>Configurations des zones</Trans>
        </Title>
        <Zones facility={facility} rooms={rooms} />
      </div>
    </>
  )
}
