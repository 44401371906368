import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { DatabaseSchema } from 'common/databaseSchema'
import { demoFacilityName } from 'common/demo'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { Centered } from 'shared/components/Centered'
import { MergedType } from 'shared/hooks/createUseMergedFirebase'
import {
  AlertType,
  alertTypeColorHex,
  testAlertTypes,
} from 'shared/types/alert'
import { ALERT_TYPE_TRANSLATE } from 'shared/types/alert.i18n'
import { Facility } from 'shared/types/reporting'
import { HourMinute } from 'shared/types/timeRange'
import { FacilityName } from 'shared/types/utils'
import { dateTimeFromISO } from 'shared/utils/time'
import { isWithinTimeRange } from 'shared/utils/timeRange'
import { writeFile, utils as xlsxUtils } from 'xlsx'
import { AlertTypesSelector } from './components/AlertTypesSelector'
import { Button } from './components/Button'
import { DurationSelector } from './components/DurationSelector'
import { Title } from './components/Text'
import { TimeRangeSelector } from './components/TimeRangeSelector'
import { useMergedFirebase } from './hooks/useMergedFirebase'
import { getDatesAfter, prepareChartDataForTotal } from './utils/statistics'

interface Props {
  facilityName: FacilityName
  facility: Facility
}

const otherColor = '#334155'

const initialDuration = 3
const initialStartTime = '00:00'
const initialEndTime = '23:59'

const ALL_ALERT_TYPE_STRING = Object.fromEntries(
  Object.entries(ALERT_TYPE_TRANSLATE).filter(
    ([type, _]) =>
      !testAlertTypes.includes(type as AlertType) &&
      (type as AlertType) !== 'CANCEL_ROOM_EXIT',
  ),
) as Record<AlertType, { id: string }>

export const StatisticsReport: React.FC<Props> = ({
  facilityName,
  facility,
}) => {
  const TOTAL = t`Total`
  const { _: lingui } = useLingui()

  // sorted alert types by string
  const allAlertTypes = useMemo(
    () =>
      Object.keys(ALL_ALERT_TYPE_STRING).sort((a, b) => {
        return i18n
          ._(ALL_ALERT_TYPE_STRING[a as AlertType])
          .localeCompare(lingui(ALL_ALERT_TYPE_STRING[b as AlertType]))
      }) as AlertType[],
    [lingui],
  )

  const [selectedAlertTypes, setSelectedAlertTypes] = useState(allAlertTypes)
  const [dates, setDates] = useState<string[]>([])
  const [duration, setDuration] = useState(initialDuration)
  const [startTime, setStartTime] = useState<HourMinute>(initialStartTime)
  const [endTime, setEndTime] = useState<HourMinute>(initialEndTime)

  useEffect(() => {
    const startDate =
      facilityName === 'DEMO-OSO'
        ? DateTime.now().minus({ days: 21 })
        : DateTime.now()
            .startOf('month')
            .minus({ months: duration - 1 })

    setDates(getDatesAfter(startDate))
  }, [duration, facilityName])

  const alertsRefPathsMap = useMemo(
    () =>
      dates.reduce<Record<string, string>>((acc, date, index) => {
        acc[date] =
          facilityName === demoFacilityName
            ? `demo/alerts/${index}`
            : `alerts/${facilityName}/${date}`
        return acc
      }, {}),
    [dates, facilityName],
  )

  const {
    data: alerts,
    loading,
    error,
  } = useMergedFirebase<
    MergedType<'alerts/${string}/${string}', DatabaseSchema>
  >(alertsRefPathsMap)

  const data = useMemo(() => {
    const endDate = DateTime.now()
    const startDate = endDate.startOf('month').minus({ months: duration - 1 })
    const timeRange = { start: startTime, end: endTime }

    const results: Record<string, Record<AlertType, number>> = {}
    const totalByType: Record<AlertType, number> = {} as Record<
      AlertType,
      number
    >
    const totalByMonth: Record<string, number> = {}

    for (const [date, dateAlerts] of Object.entries(alerts)) {
      if (dateAlerts != undefined) {
        for (const roomAlerts of Object.values(dateAlerts)) {
          for (const alert of Object.values(roomAlerts)) {
            const alertHourDate = dateTimeFromISO(alert.date)
            const alertDate = dateTimeFromISO(date) // use date instead of alert.date for demo compatibility
            if (
              selectedAlertTypes.includes(alert.type) &&
              alertDate >= startDate &&
              alertDate <= endDate &&
              isWithinTimeRange(alertHourDate, timeRange)
            ) {
              const month = alertDate.toFormat('yyyy-MM')
              results[month] = results[month] ?? {}
              results[month][alert.type] = results[month][alert.type] ?? 0
              results[month][alert.type]++
              totalByMonth[month] = (totalByMonth[month] || 0) + 1
              totalByType[alert.type] = (totalByType[alert.type] || 0) + 1
            }
          }
        }
      }
    }
    results[TOTAL] = totalByType
    totalByMonth[TOTAL] = Object.values(totalByType).reduce(
      (acc, current) => acc + current,
      0,
    )
    return { results, totalByMonth }
  }, [alerts, selectedAlertTypes, duration, startTime, endTime, TOTAL])

  const dataForChart = useMemo(() => {
    return prepareChartDataForTotal(data.results[TOTAL])
  }, [data, TOTAL])

  const months = Object.keys(data.results).sort()

  const handleExport = () => {
    const wb = xlsxUtils.book_new()
    const { name, address, city, zipCode, monitoringTimeRange } = facility

    const infoData = [
      [t`Nom de l'établissement:`, name],
      [t`Adresse:`, `${address}, ${zipCode} ${city}`],
      [
        t`Plage horaire de surveillance:`,
        t`De ${monitoringTimeRange.start} à ${monitoringTimeRange.end}`,
      ],
      [t`Durée:`, t`${duration} mois`],
      [t`Plage horaire sélectionnée`, t`De ${startTime} à ${endTime}`],
      [t`Date de génération du fichier:`, DateTime.now().toISODate()],
      [], // Ligne vide pour séparation
    ]

    const header = [t`Type d'alerte`, ...months]
    const alertData = selectedAlertTypes.map((type) => [
      lingui(ALERT_TYPE_TRANSLATE[type].id),
      ...months.map((month) =>
        data.results[month] && data.results[month][type]
          ? data.results[month][type]
          : 0,
      ),
    ])
    alertData.push([
      t`Total`,
      ...months.map((month) => data.totalByMonth[month]),
    ])

    const combinedData = [...infoData, header, ...alertData]

    const ws = xlsxUtils.aoa_to_sheet(combinedData)
    ws['!cols'] = [{ wch: 20 }, ...months.map(() => ({ wch: 10 }))]
    xlsxUtils.book_append_sheet(wb, ws, t`Rapport Complet`)

    writeFile(wb, `${t`Rapport_Alertes_${DateTime.now().toISODate()}`}.xlsx`)
  }

  return (
    <div className="rounded-lg bg-white p-6 shadow-md">
      <AlertTypesSelector
        allAlertTypes={allAlertTypes}
        selectedAlertTypes={selectedAlertTypes}
        toggleAlertType={(type) =>
          setSelectedAlertTypes((prev) =>
            prev.includes(type)
              ? prev.filter((t) => t !== type)
              : [...prev, type],
          )
        }
      />
      <DurationSelector duration={duration} setDuration={setDuration} />
      <TimeRangeSelector
        startTime={startTime}
        endTime={endTime}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
      />
      {loading ? (
        <Centered fullScreen>
          <Trans>Chargement...</Trans>
        </Centered>
      ) : error ? (
        <Centered fullScreen>
          <Trans>Erreur</Trans>
        </Centered>
      ) : (
        <>
          <Title>
            <Trans>Répartition globale des alertes</Trans>
          </Title>
          <ResponsiveContainer width="100%" height={500}>
            <PieChart>
              <Pie
                data={dataForChart}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, percent }) =>
                  `${name}: ${(percent * 100).toFixed(0)}%`
                }
                outerRadius="60%"
                fill="#8884d8"
                dataKey="value"
              >
                {dataForChart.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      alertTypeColorHex[entry.type as AlertType] || otherColor
                    }
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <Title>
            <Trans>Détail du nombre d'alertes par mois</Trans>
          </Title>
          <div className="mb-6 flex">
            <Button onClick={handleExport}>
              <Trans>Exporter au format Excel</Trans>
            </Button>
          </div>
          <div>
            <table className="min-w-full table-auto text-left text-sm">
              <thead className="bg-gray-300">
                <tr>
                  <th className="px-2 py-1">
                    <Trans>Type / Mois</Trans>
                  </th>
                  {months.map((month) => (
                    <th key={month} className="px-2 py-1">
                      {month}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {selectedAlertTypes.map((type) => (
                  <tr
                    key={type}
                    className="border-b border-gray-700 even:bg-gray-100"
                  >
                    <td className="px-2 py-1">
                      {lingui(ALERT_TYPE_TRANSLATE[type])}
                    </td>
                    {months.map((month) => (
                      <td key={month} className="px-2 py-1">
                        {data.results[month] && data.results[month][type]
                          ? data.results[month][type]
                          : 0}
                      </td>
                    ))}
                  </tr>
                ))}
                <tr className="bg-gray-300 font-bold">
                  <td className="px-2 py-1">
                    <Trans>Total</Trans>
                  </td>
                  {months.map((month) => (
                    <td key={month} className="px-2 py-1">
                      {data.totalByMonth[month]}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  )
}
