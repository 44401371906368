import { Trans } from '@lingui/macro'
import { CalendarCheck2 as CalendarIcon } from 'lucide-react'
import React, { useState } from 'react'
import { Dialog } from 'shared/components/Dialog'
import { Zone as ZoneType } from 'shared/types/fleet'
import { Room } from 'shared/types/utils'
import { Zone } from './Zone'

interface Props {
  zoneName: string
  holidayZone: ZoneType
  rooms: Room[]
}

export const HolidayZone: React.FC<Props> = ({
  zoneName,
  holidayZone,
  rooms,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <>
      <CalendarIcon
        strokeWidth={1}
        size={20}
        onClick={() => setIsVisible(true)}
        className="cursor-pointer text-gray-400 hover:text-gray-700"
      />
      {isVisible && (
        <HolidayZoneDialog
          zoneName={zoneName}
          holidayZone={holidayZone}
          rooms={rooms}
          onClose={() => setIsVisible(false)}
        />
      )}
    </>
  )
}

type DialogProps = {
  zoneName: string
  holidayZone: ZoneType
  rooms: Room[]
  onClose: () => void
}

const HolidayZoneDialog: React.FC<DialogProps> = ({
  onClose,
  holidayZone,
  zoneName,
  rooms,
}) => {
  return (
    <Dialog onClose={onClose}>
      <div className="relative rounded-md bg-white p-4">
        <div className="flex flex-col gap-6">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <div className="text-xl font-bold">{zoneName}</div>
              <span>
                <Trans>Jours fériés et weekends</Trans>
              </span>
            </div>
            <div
              className="absolute right-2 top-2 flex h-10 w-10 cursor-pointer flex-row items-center justify-center rounded-full bg-blue-500 p-2 text-3xl text-white hover:bg-blue-700"
              onClick={onClose}
            >
              &times;
            </div>
          </div>
          <Zone zone={holidayZone} rooms={rooms} holidayZone={undefined} />
        </div>
      </div>
    </Dialog>
  )
}
