import { MessageDescriptor } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useEffect, useState } from 'react'
import { RoomConfigName } from 'shared/types/fleet'
import { RoomConfigs as RoomConfigsType } from 'shared/types/reporting'
import { FacilityName } from 'shared/types/utils'
import { translatedTimeRangeString } from 'shared/utils/timeRange.i18n'
import { collator } from 'shared/utils/web/collator'
import { get } from './firebaseMethods'

interface Props {
  facilityName: FacilityName
}

const configNamesSorted = ['monitoringTimeRange', 'roomExitTimeRange']

const configNameDisplay: Record<RoomConfigName, MessageDescriptor> = {
  roomExitTimeRange: msg`Détection des sortie de chambre`,
  monitoringTimeRange: msg`Plage horaire de surveillance`,
}

export const RoomConfigs: React.FC<Props> = ({ facilityName }) => {
  const { _: lingui } = useLingui()

  const [loading, setLoading] = useState<boolean>(true)
  const [roomConfigs, setRoomConfigs] = useState<RoomConfigsType | undefined>()

  useEffect(() => {
    async function runASync() {
      const roomConfigs = await get(`roomConfigs/${facilityName}`)
      setRoomConfigs(roomConfigs)
      setLoading(false)
    }
    runASync()
  }, [facilityName])

  if (loading)
    return (
      <div>
        <Trans>Chargement...</Trans>
      </div>
    )

  if (!roomConfigs) {
    return (
      <div>
        <Trans>Aucune configuration de chambre dans l'établissement</Trans>
      </div>
    )
  }

  return (
    <div className="flex w-full max-w-screen-md flex-col gap-2">
      {Object.entries(roomConfigs)
        .sort(([roomA], [roomB]) => collator.compare(roomA, roomB))
        .map(([room, configs]) => {
          return (
            <div
              key={room}
              className="flex flex-col gap-4 border-t border-gray-400 p-4 first:border-none sm:flex-row"
            >
              <div className="text-center font-bold sm:w-1/4 sm:text-start">
                {room}
              </div>

              <div className="flex flex-col justify-center gap-4 sm:w-3/4 sm:justify-end">
                {configNamesSorted.map((configName) => {
                  const configValue = configs[configName as RoomConfigName]

                  if (configValue === undefined) return null

                  return (
                    <div
                      key={`${configName}_${room}`}
                      className="flex flex-col justify-between sm:flex-row "
                    >
                      <div className="text-center">
                        {lingui(
                          configNameDisplay[configName as RoomConfigName],
                        )}
                      </div>
                      <div className="text-center">
                        {translatedTimeRangeString(configValue)}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
    </div>
  )
}
