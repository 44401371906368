import { Trans } from '@lingui/macro'
import React from 'react'
import { Facility } from 'shared/types/reporting'
import { Room } from 'shared/types/utils'
import { zoneSorter } from 'shared/utils/zone'
import { Zone } from './Zone'

interface Props {
  facility: Facility
  rooms: Room[]
}

export const Zones: React.FC<Props> = ({ facility, rooms }) => {
  const zones = facility.zones

  if (!zones) {
    return (
      <div>
        <Trans>Aucune configuration de zone dans l'établissement</Trans>
        <Trans>Contactez-nous si vous souhaitez les mettre en place.</Trans>
      </div>
    )
  }

  const filteredZones = Object.fromEntries(
    Object.entries(zones).filter(([, zone]) => !zone.isHoliday),
  )

  return (
    <div className="flex w-full max-w-screen-md flex-col gap-2">
      {Object.entries(filteredZones)
        .sort(zoneSorter)
        .map(([key, zone]) => (
          <Zone
            key={key}
            zone={zone}
            rooms={rooms}
            holidayZone={
              zone.holidayZoneId ? zones[zone.holidayZoneId] : undefined
            }
          />
        ))}
    </div>
  )
}
