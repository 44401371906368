import { msg } from '@lingui/macro'
import { ChangeLogEntry } from 'common/types'

export const CHANGELOG: Record<string, ChangeLogEntry> = {
  '24-05-13': {
    text: msg`Il est maintenant possible de consulter la définition des zones de votre établissement dans l'onglet 'Zones' et de filtrer les alertes en fonction de ces zones.`,
    timestamp: 1715551200000,
    title: msg`Filtrage des alertes par zone`,
  },
  '24-05-14': {
    text: msg`Il est maintenant possible de consulter l'activité des résidents en différenciant le jour et la nuit.`,
    timestamp: 1715637600000,
    title: msg`Filtrage des activités par jour/nuit`,
  },
  '24-05-21': {
    text: msg`Des statistiques mensuelles sur le nombre et le type d'alertes dans votre établissement sont désormais disponibles.`,
    timestamp: 1716242400000,
    title: msg`Nouvel onglet Statistiques`,
  },
  '24-05-29': {
    text: msg`Les zones modifiées les jours fériés et les weekends sont désormais visibles dans l'onglet Zones (clic sur icone 'calendrier')`,
    timestamp: 1716996000000,
    title: msg`Gestion des jours fériés et weekends`,
  },
  '24-07-19': {
    text: msg`De courtes vidéos détaillant notre service s'affichent sur les téléphones, en prévision des remplacements des congés d'été.`,
    timestamp: 1721340000000,
    title: msg`Vidéos tutoriel sur les téléphones`,
  },
  '25-01-15': {
    text: msg`Les configurations des chambres et des zones sont désormais regroupées sous l'onglet Configuration.`,
    timestamp: 1736899200000,
    title: msg`Nouvel onglet Configuration`,
  },
}
