import { DateString } from 'shared/types/utils'
import { dateTimeFromISO } from 'shared/utils/time'

export function formatDayOfWeek(date: DateString) {
  // ISO 8601 YYYY-MM-DD format -> Lundi 10 avril
  return dateTimeFromISO(date).toLocaleString({
    weekday: 'long',
    month: 'long',
    day: '2-digit',
  })
}

export function formatDate(date: DateString) {
  // ISO 8601 YYYY-MM-DD format -> DD/MM
  return dateTimeFromISO(date).toLocaleString({
    day: '2-digit',
    month: '2-digit',
  })
}

export function getDay(date: DateString) {
  // ISO 8601 YYYY-MM-DD format -> DD
  return `${date[8]}${date[9]}`
}
